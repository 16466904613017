import React, { Component } from 'react';
import Resume from '../../components/Resume/Resume';

class ResumePage extends Component {

    render () {
        return (
            <div className="center" id="Resume">
                <Resume />
            </div>
        )
    }
}

export default ResumePage;