import React, { Component } from 'react';
import Process from '../../components/Process/Process';

class ProcessPage extends Component {

    render () {
        return (
            <div className="center" id="Process">
                <Process />
            </div>
        )
    }
}

export default ProcessPage;