import React, { Component } from 'react';
import Bio from '../../components/Bio/Bio';

class BioPage extends Component {

    render () {
        return (
            <div className="center" id="Bio">
                <Bio />
            </div>
        )
    }
}

export default BioPage;