import React, { Component } from 'react';
import Skills from '../../components/Skills/Skills';

class SkillsPage extends Component {
    render () {
        return (
            <div id="Skills">
                <Skills />
            </div>
        )
    }
}

export default SkillsPage;