import React, { Component } from 'react';
import Contact from '../../components/Contact/Contact';

class ContactPage extends Component {
   render(){
       return (
           <div id="Contact">
               <Contact />
           </div>
       )
   }
};

export default ContactPage;